/* .product-text {
    text-align: center;
    font-weight: 900;
    font-size: 2rem;
    color: green;
} */

.product {
    text-align: center;
    font-weight: 900;
    font-size: 2rem;
    color: green;
}

.marginTop {
    margin-top: 5%;
}

/* .disclaimer-paragraph::before {
    content: '\201C';
    font-size: 2.5rem;
    color: black;
}

.disclaimer-paragraph::after {
    content: '\201D';
    font-size: 2.5rem;
    color: black;
} */

.custom-shape-divider-bottom-1688987183 {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-bottom-1688987183 svg {
    position: relative;
    display: block;
    width: calc(132% + 1.3px);
    height: 112px;
}

.custom-shape-divider-bottom-1688987183 .shape-fill {
    fill: #FFFFFF;
}

.button-margin {
    margin-right: 0.5rem;
    color: green;
    font-weight: bolder;
    background-color: #fff;
    border: transparent;
    border-radius: 5px;
}