
.custom-shape-divider-top-1689872432 {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    overflow: hidden;
    line-height: 0;
    transform: rotate(180deg);
}

.custom-shape-divider-top-1689872432 svg {
    position: relative;
    display: block;
    width: calc(134% + 1.3px);
    height: 120px;
}

.custom-shape-divider-top-1689872432 .shape-fill {
    fill: #FFFFFF;
}



.other-services-header {
    text-align: center;
        font-weight: 900;
        font-size: 2rem;
        color: green;
}

.os-container-header {
    text-align: center;
}
.other-service-navlink {
    text-decoration: none;
    color: black;
}
.other-services-header::before {
    content: 'Other Services';
    font-family: 'Dancing Script';
    font-weight: 600;
    font-size: 8rem;
    color: rgb(19, 19, 20);
    margin-top: -10px;
    margin-bottom: -90px;
    opacity: .05;
    display: block;
}

.fitting {
    max-width: 100%;
    width: 100%;
    border: 1px solid transparent;
        position: relative;
        justify-content: center;
        align-items: center;
        box-shadow: 5px 4px 12px 1px rgba(0, 0, 0, 0.59);
}
.fitting-overlay {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    background: #002400;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.fitting-content {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: #fff;

}

.fitting:hover .fitting-overlay {
    opacity: 0.8;
}
@media (max-width: 450px) {
    .other-services-header {
        font-size: 2rem;
        padding-top: 1.5rem;
    }
}

@media (max-width: 992px) {
    .other-services-header::before {
        display: none;
    }
}