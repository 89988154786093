.fitting-heading {
    text-align: center;
    color: green;
    font-weight: 900;

}

.fitting-second-head {
    width: 80%;
    margin: 1rem auto;
    /* border: 1px solid red; */
    color: green;
    font-weight: bolder;
    color: #fff;
    padding: 2rem;
} 

.fitting-col {
    background-color: #82CD47;
    color: #fff;
    padding: 1rem;
    flex: 1 1 auto;
}

.fitting-col-headers {
    color: green;
    font-weight: bolder;
    text-align: center;
    border-bottom: 3px solid green;
    padding-bottom: 0.5rem;
}

@media (min-width: 992px) {
    .fitting-col-bg {
            padding-top: 2rem;
        }
        .fitting-img {
            padding-top: 1.5rem;
        }
}