 .fixed-whatsapp {
    position: fixed;
    width: 2.4rem;
    height: 2.4rem;
    bottom: 30rem;
    right: 0.6rem;
    padding: 10px;
    
    background-color: #25d366;
    color: #FFF;
    border-radius: 100%;
    box-shadow: 0.08rem 0.08rem 0.12rem #999;
    z-index: 100;
    cursor: pointer;    
} 


@media (max-width: 768px) {
        .fixed-whatsapp {
            position: fixed;
            width: 4rem;
            height: 4rem;
            max-height: 100%;
            max-width: 100%;
            padding: 10px;
            bottom: 15rem;
            
            right: 1.25rem;
            color: #fff;
                background-color: #25d366;
                border-radius: 100%;
                /* box-shadow: 0.16rem 0.16rem 0.25rem #999; */
                z-index: 100;
                cursor: pointer;
        }
}

@media (min-width: 768px) {
    .fixed-whatsapp {
        position: fixed;
            width: 4rem;
            height: 4rem;
            max-height: 100%;
            padding: 10px;
            max-width: 100%;
            bottom: 15rem;
            right: 1.25rem;
            color: #fff;
            background-color: #25d366;
            border-radius: 100%;
            box-shadow: 0.16rem 0.16rem 0.25rem #999;
            z-index: 100;
            cursor: pointer;
    }
}

@media (min-width: 1200px) {
    .fixed-whatsapp {
        position: fixed;
        width: 3rem;
        height: 3rem;
        padding: 10px;
        max-height: 100%;
        max-width: 100%;
        bottom: 15rem;
        right: 1.25rem;
        color: #fff;
        background-color: #25d366;
        border-radius: 100%;
        box-shadow: 0.16rem 0.16rem 0.25rem #999;
        z-index: 100;
        cursor: pointer;
    }
}

