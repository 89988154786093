.servicing-heading {
text-align: center;
    color: green;
    font-weight: 900;
}

.servicing-second-head {
    width: 80%;
    margin: 1rem auto;
    /* border: 1px solid red; */
    color: #fff;
    padding: 2rem;
    font-weight: bolder;
}

.servicing-col-headers {
    color: green;
    font-weight: bolder;
    text-align: center;
    border-bottom: 3px solid green;
    padding-bottom: 0.5rem;
}

.servicing-col {
    background-color: #82CD47;
    color: #fff;
    padding: 1rem;
    flex: 1 1 auto;
}

@media (min-width: 992px) {
    .servicing {
            padding-top: 1rem;
        }
        .servicing-img {
            padding-top: 1.5rem;
            margin-top: 4rem;
        }
}