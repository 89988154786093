#add-to-cart {
    background-color: green;
    border-radius: 38px;
    color: white;
}

#add-to-cart:hover {
    background-color: rgb(50, 205, 50);
    color: #fff;
}

#add-to-cart:active {
    background-color: rgb(50, 205, 50);
    color: #fff;
}

.product-card {
    max-width: 400px;
    width: 100%;
    padding: 0.5rem;
    border-radius: 50px;
}

.img-width {
    width: 100%;
    height: 250px;
    border-radius: 50px;
    max-width: 400px;
    object-fit: cover;
}
/* 
.product-price {
    text-decoration: none;
} */