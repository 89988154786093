.signup-top-container {
    padding-top: 2rem;
}

.signup-header {
    text-align: center;
    color: green;
    font-weight: 900;
}

.signup-form-img {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
}

#signup-google {
        background-color: green;
        color: #fff;
        border: none;
        border-radius: 38px;
        padding: 5px 20px; 
        font-size: large;
}

#signup-googgle:active {
    background-color: rgb(50, 205, 50);
}

#signup-google:hover {
    background-color: rgb(50, 205, 50);
}

#form-signup-btn {
    background-color: green;
    color: #fff;
    width: 100%;
    border-radius: 38px;

}

.form-signup-btn:hover {
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    background-color: rgb(50, 205, 50)
}

.form-signup-btn:active {
    background-color: rgb(50, 205, 50);
}

.signup-form-row-align {
    justify-content: center;
}

/* .login-google {
    background-color: rgb(234, 55, 33);
    color: #fff;
    border: none;
    border-radius: 38px;
    padding: 5px 20px;
    font-size: large;
} */

/* .login-google:hover {
    background-color: rgb(241, 113, 96);
} */
.signup-form-column {
    display: flex;
    flex-direction: column;
    justify-content: center;
    
    
}
.signup-form-txt-btn {
    margin-left: 20px;
}


.signup-form-icon-btn {
    border-right: 1px solid #fff;

}

.signup-icon {
    margin-right: 5px;
}


.form-signup-link-txt-decoration {
    text-decoration: none;
}

.signup-googleIcon-span {
    border-right: 2px solid #fff;
}

@media(max-width: 992px) {
    .signup-form-column {
        display: none;
    }
}