.top-container {
    min-height: 50vh;
    padding-top: 2rem;
}

.forgotpassword-header {
    text-align: center;
        color: green;
        font-weight: 900;
}

.forgot-password-btn {
    background-color: green;
    border-radius: 38px;
    color: #fff;
    width: 100%;
}

.forgot-password-btn:hover {
    background-color: rgb(50, 205, 50)
}

.forgotpassword-form-row-align {
    justify-content: center;
}