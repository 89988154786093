.aboutus-heading {
    text-align: center;
    color: green;
    font-weight: 900;
    margin-top: 1rem;
    padding-top: 3rem;
}

@media(max-width: 992px) {
    .order {
        order: 1
    }
}