.cart-top-container {
    padding-top: 2rem;
    /* background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" fill="%23C8FFC8"><path d="M994 112c-703-2-920.47 400.35-904 905 13.35 409 32.03 946.66 977 861 684-62 792-279 835-777 61.67-714.25-288.33-987.24-908-989Z"></path></svg>');
    background-repeat: no-repeat;
    background-position: center;
    background-size: contain; */
    
}

.cart-blob-divider {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 2000" fill="%23C8FFC8"><path d="M1891 560c0-277-224-509-501-499-372 13-397 281-504 302s-215-179-511-75a459 459 0 0 0-222 218C10 809 262 886 259 992s-201 171-82 395c34 64 99 127 212 141 56 6 103 47 115 102a377 377 0 0 0 383 310c222 2 528-202 380-536-42-97 30-123 73-194s-11-120 128-156c233-61 423-246 423-494Z"></path></svg>');
    background-repeat: no-repeat;
        background-position: bottom-center;
        background-size: cover;
}
.cart-header {
    font-size: 900;
    margin-bottom: 30px;
    text-align: center;
    color: green;
}

.cart-headerh4 {
    text-align: center;
}

#cart-button-color {
    background-color: green;
}


#cart-button-color:hover {
background-color: rgb(50, 205, 50)
}

#cart-button-color:disabled {
    background-color: rgb(50, 205, 50);
}

#cart-button-color:active {
background-color: rgb(50, 205, 50)
}

#cart-button-decrease {
    background-color: green;
}

#cart-button-decrease:hover {
background-color: rgb(50, 205, 50);
}

#cart-button-decrease:disabled {
background-color: rgb(50, 205, 50);
}

#cart-button-decrease:active {
background-color: rgb(50, 205, 50);
}

#cart-button-remove {
    background-color: green;
}

#cart-button-remove:hover {
    background-color: rgb(50, 205, 50);
}

#cart-button-remove:active {
    background-color: rgb(50, 205, 50);
}

#cart-button-remove:disabled {
    background-color: rgb(50, 205, 50);
}

#cart-button-proceed {
    background-color: green;
    border-radius: 38px;
}

#cart-button-proceed:active {
    background-color: rgb(50, 205, 50);
}

#cart-button-proceed:hover {
    background-color: rgb(50, 205, 50);
}

#cart-button-proceed:disabled {
    background-color: rgb(50, 205, 50);
}






.cart-total-price-color {
    color: green;
}

.cart-container-glow {
    -webkit-box-shadow: 0px 0px 240px 52px rgba(45, 255, 196, 0.44);
        -moz-box-shadow: 0px 0px 240px 52px rgba(45, 255, 196, 0.44);
        box-shadow: 0px 0px 240px 52px rgba(45, 255, 196, 0.44);
}

.cart-button-quantity {
    margin-left: 0.5rem;
    margin-right: 0.5rem;

}

@media(max-width: 992px) {
    .cart-button {
            margin-top: 0.5rem;
            margin-bottom: 0.5rem;
            
        }
    .cart-button-quantity {
        margin-left: 0.5rem;
        margin-right: 0.5rem;
    }
    
}

@media(max-width: 992px) {
    .cart-img-column {
        display: none;
    }
}