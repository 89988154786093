.order-page-container {
    padding-top: 2rem;
}

.order-page-heading {
    text-align: center;
    color: green;
}

.order-page-blob-divider {
    background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 2000 1155" fill="%23C8FFC8"><path d="M968.99 1033c-187.45.85-174.59 47.22-449 48-352 1-463-239-463-548 0-273.09 190-474 496-472 277.28 1.81 277.22 60.83 470 57 252-5 217.88-56.12 487-57 305-1 431 290 433 526s-117.08 502.4-432 507c-342 5-322-62-542-61Z"></path></svg>');
        background-repeat: no-repeat;
        background-position: bottom-center;
        background-size: cover;
}

