

section {
position: relative;
}
.col-image-center {
    justify-content: center;
    align-items: center;
}





.home-shop-btn {
    background-color: green;
    color: #fff;
    width: 100%;
    border-radius: 38px;
    font-size: 1.2rem;
}

/* .product-categories {
    max-width: 100%;
    width: 100%;

    border: 1px solid transparent;
    position: relative;
    justify-content: center;
    align-items: center;
box-shadow: 5px 4px 12px 1px rgba(0, 0, 0, 0.59);
}

.product-categories-text {
    text-align: center;
} */


.latest-deal {
    width: 100%;
    height: 100%;
    color: green;
}

.latest-deal-heading {
    border-bottom: 2px solid green;
    padding-bottom: 7px;
    font-weight: bolder;
    color: green;
   
}

.latest-deal-desctiption-container {
    display: flex;

}

.latest-deal-img {
    width: 100%;
        height: 100%;
}


.promo-sales-heading {
    padding-bottom: 7px;
    color: green;
    font-weight: Bolder;
    border-bottom: 2px solid green;
}

.promo-sales-col {
    border-radius: 3.125rem;
}


.brand-header {
    font-weight: bolder;
    color: green;

}

.brand-text {
    color: green;
    
}

@media(max-width: 100px) {
    .marginTop {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
}
































.overlay {
    position:absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    transition: opacity 0.4s ease-in-out;
    background: #002400;
    cursor: pointer;
    overflow: hidden;
    width: 100%;
    height: 100%;
    z-index: 2;
}

.content {
    position: absolute;
    top: 50%;
    left: 50%;
    text-align: center;
    transform: translate(-50%, -50%);
    color: white;
    
}

.product-categories:hover .overlay {
    opacity: 0.8;
}


.product-text::before {
    content: 'Products';
    font-family: 'Dancing Script';
    font-weight: 600;
    font-size: 8rem;
    color: rgb(19, 19, 20);
    margin-top: -10px;
    margin-bottom: -90px;
    opacity: .05;
    display: block;
}


.home-container-col {
    margin: 0 auto;
}
.home-shop-btn:hover {
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
    transition: 0.4s;
    background-color: rgb(50, 205, 50)
}

/* @media (max-width: 450px) {
.home-headings {

}
.home-paragraphs {

}
.heading-font {

}
} */
@media(max-width: 450px) {
    .product-text {
    
            font-size: 2rem;
            margin-bottom: 30px;
            color: green;
        }
                   /* .home-headings {
                       text-align: left;
                   } */
}
@media (max-width: 768px) {
 
     
}

@media(max-width: 992px) {
    .product-text::before {
        display: none;

    }
    .latest-deal-row {
        justify-content: space-around;
    }

}