* {
  box-sizing: border-box;
}

body {
  width: 100%;
  margin: 0;
  padding-top: 5rem;
  padding-bottom: 3.5rem;
  font-family: Montserrat, Poppins, -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}




@media (max-width: 450px) {
  html {
    font-size: 16px;
  }
}

@media (min-width: 450px) and (max-width: 1200px ) {
    html {
      font-size: 16px;
    }
}

@media (min-width: 992px) {
  body {
    padding-bottom: 0;
  }
}

@media (min-width: 1200px) {
  html {
    font-size: 18px;
  }
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
