
 .badge-pill {
     height: 100%;
     border-radius: 90%;
     font-size: 20px;
     font-weight: bold;
     color: #fff;
     position: relative;
     left: -1px;
     bottom: 8px;
     /* padding-left: 1rem;
    padding-right: 1rem;
    padding-top: 2rem;
    padding-bottom: 2rem; */
}

.layout-logout {
    color: rgb(50, 205, 50);
}

.layout-brand-logo {
    margin-right: 1rem;
    margin-top: 0.6rem;
}

.layout-text {
    margin: 0 auto;
    text-align: center;
    background-color: #002400;
    color: #fff;
}

#dropdown-basic-button {
    background-color: green;
    border-radius: 38px;
    color: #fff;
}

#dropdown-basic-button:hover {
    background-color: rgb(50, 205, 50);
}

#dropdown-basic-button:active {
    background-color: rgb(50, 205, 50);
}
.layout-position {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    z-index: 500;
    }

.layout-logo {
    height: auto;
    width: auto;
    padding: 0;
}
.nav-bgcolor {
    background-color: #fff;
    color: black;
    font-family: 'Poppins', sans-serif;
    font-weight: 400;

}

.navlink {
    margin-right: 0.6rem;
}

.navbar-brand {
 margin-right: 0.6rem;
}


.autocomplete {
    width: 50%;
    background-color: #fff;
    color: green;
    padding: 1rem;
    position: relative;
    font-weight: bold;
    left: 10rem;
    border-bottom: 1px solid black;
}

/* .dropdown-item:hover {

    background: rgba(255, 255, 255, 0.5);
} */

.remove-padding {
    padding-left: 0;
} 

.nav-social-links {
    margin: 0 0.6rem;
    text-decoration: none;
    color: #003700;
    display: flex;
    padding-top: 17px;
}

.form-width {
    width: 60%;
}

.layout-search-input {
    border-radius: 38px;
}

.layout-icon {
    font-size: 2rem
}

#layout-search-btn {
  background-color: green;
  color:#fff;
  border-radius: 38px;
}


#layout-search-btn:hover {
    background-color: rgb(50, 205, 50)
}

#layout-search-btn:active {
    background-color: rgb(50, 205, 50)
    
}

.btn-outline-success {
    background-color: green;
}

.icon-account-paragraph {
  text-decoration: none;
  color: green;

}
a.dropdown-item {
    color: #fff;
} 

div.dropdown-menu.show {
    position: relative;
    z-index: 200;
    border-radius: 38px;
    background-color: green;
    color: white;
    
}


#signedin-btn { 
    background-color: white; 
    border-radius: 38px;
    color: #198754;
    margin-left: 0.5rem;
    width: 5.5rem;
    
}

#signedin-btn:hover {
    background-color: #333;
    color: #198754;
}

/* #siginedin-btn:active {
    background-color: rgb(50, 205, 50);
} */

#signedin-btn:hover {
    color: #fff;
}
/* .btn-primary:active {
    background-color: rgb(50, 205, 50);
}

.btn-primary:disabled {
    background-color: rgb(50, 205, 50);
}
.btn-primary:hover {
    background-color: rgb(50, 205, 50);
} */
.search-results {
    display: flex;
    flex-direction: column;
    width: 70%;
    background-color: green;
    color: #fff;
    padding: 1rem;
    padding-left: 2rem;
    
}

.border-bottom {
border-bottom: 2px solid black;
cursor: pointer;
}

.layout-icon-margin {
    margin-right: 0.3rem;
    margin-top: 0.3rem;
}



.nav-social-links:hover {
    color: rgb(50, 205, 50)
}



@media (max-width: 992px) {

    .autocomplete {
        display: none;
    }
    .mobile-autocomplete {
                width: 100%;
                height: 50vh;
                background-color: #fff;
                color: green;
                padding: 1rem;
                position: relative;
                z-index: 10;
                padding-left: 0;
               
            }
.dropdown-item {
border-bottom: 1px solid black;
margin-bottom: 1rem;

}
            .mobile-dropdownitem {
                width: 100%;
            }

      .search-results {
        width: 100%;
    }

    .layout-icon {
    font-size: 2rem;
    color: green;
}

    .nav-collapse-container {
        height: 60vh;
        padding: 0.95rem;
        position: relative;
        z-index: 9;
        /* border: 2px solid red; */

    }
    
    .hidden {
        display: none;
    }

            .layout-nav-dropdown {
                display: flex;
                flex-direction: row;
                align-items: center;
                margin-bottom: 0.47rem;
            }

     .nav-social-links {
        /* padding: 5px 30px; */
        text-decoration: none;
        color: #003700;
        margin: 0; 
        margin-bottom: 0.47rem;
        }
        .hidden {
            display: none;
        }
        .cart-paragraph {
            margin-top: 8px;
           
        }
        .form-width {
            width: 100%;
            margin-bottom: 0.95rem;
        }

        .mobile-search-icon {
            font-size: x-large;
            
        }
    
}

  /* @media (min-width: 992px ) 
 and (max-width: 1200px) {
        
            .layout-icon {
                font-size: 1.3rem;
            }
        
            .nav-container {
                justify-content: space-around;
            }
        
            .layout-nav-dropdown {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-left: 2.1rem;
            }
}   */

@media (min-width: 992px){

    .layout-icon {
    font-size: 1rem;
    
  }

  .desktop-search-icon {
    margin: 0;
    font-size: 1.2rem;
  }
    .nav-container {
        justify-content: space-around;
        align-items: center;
    }

    .mobile-autocomplete {
        display: none;
    }

}
