.login-top-container {
    padding-top: 2rem;
}

.login-header {
    text-align: center;
    color: green;
    font-weight: 900;
}

.login-form-img {
    width: 100%;
    max-width: 100%;
    min-height: 100%;
}

#form-login-btn {
            background-color: green;
            color: #fff;
            width: 100%;
            border-radius: 38px;
        
}

#form-login-btn:active {
    background-color: rgb(50, 205, 50); 
}

#form-login-btn:hover {
    box-shadow: 0 0 20px rgba(104, 85, 224, 0.2);
        transition: 0.4s;
        background-color: rgb(50, 205, 50)
}

#form-login-btn:disabled {
    background-color: rgb(50, 205, 50);
}
.login-form-row-align {
    justify-content: center;
}

#login-google {
    background-color: green;
color:#fff;
border: none;
border-radius: 38px;
padding: 5px 20px;
font-size: large;
}

#login-google:active {
    background-color: rgb(50, 205, 50);
}

#login-google:hover {
    background-color: rgb(50, 205, 50);
}

.login-form-txt-btn {
    margin-left: 20px;    
} 

#login-password-button-reveal {
    background-color: green;
    border-radius: 36px;
}
#login-password-button-reveal:hover {
    background-color: rgb(50, 205, 50);
}
#login-password-button-reveal:disabled {
    background-color: rgb(50, 205, 50);
}

#login-password-button-reveal:active {
    background-color: rgb(50, 205, 50);
}

.login-form-icon-btn {
    border-right: 1px solid #fff;

}

.login-icon {
    margin-right: 5px;
}

/* .login-facebook {
    background-color: blue;
    color: #fff;
    border-radius: 38px;
    padding: 5px 20px;
    font-size: large;
} */

.form-login-link-txt-decoration {
    text-decoration: none;
}
.login-googleIcon-span {
    border-right: 2px solid #fff;
}

@media(max-width: 992px) {
    .login-form-col {
        display: none;
    }
}