nav {
    position: fixed;
    display: flex;
    justify-content: space-around;
    width: 100%;
    bottom: 0;

    background-color: #313846;
    z-index: 100;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    border-width: 1px 0 0 0;
    border-style: solid;
        /* border-color: rgba(56, 68, 79, 1);
        background: rgba(49, 56, 70, 1); */
        background: rgba(0,0,0,0.9);
        box-shadow: 5px 10px 30px -5px rgba(0, 0, 0, 0.3);
}

.mobile-footer-link {
 display: flex;
 flex-direction: column;
 justify-content: center;
 align-items: center;
    padding: 0.5rem;
    text-decoration: none;
}

.mobile-footer-link p {
margin: 0;
color: white;
font-size: 1rem;
}

.mobile-footer-icon {
    color: white;
}
@media (min-width: 992px) {
    nav {
        display: none;
    }
}



 .badge-pill {
    height: 100%;
    border-radius: 90%;
    font-size: 20px;
    font-weight: bold;
    color: #fff;
    position: relative;
    left: -1px;
    bottom: 8px;
 }

 















































/* @media(max-width: 992px) {
    nav {
            background: rgba(0, 0, 0, 0.3);
            width: 100%;
            display: block;
            padding: 0.7rem 1.7rem;
            z-index: 3;
            position: fixed;
            left: 0;
            right: 0;
            transform: translateX(-50%);
            bottom: 0;
            display: flex;
            gap: 0.8rem;
            border-radius: 3rem;
            backdrop-filter: blur(15px);
        }
    
        nav .mobile-footer-link {
            background: transparent;
            padding: 0.9rem;
            border-radius: 50%;
            display: flex;
            color: green;
            font-size: 2.1rem;
        }
    
        nav .mobile-footer-link:hover {
            background: rgba(0, 0, 0, 0.3);
        }
     */
        /* nav .mobile-footer-link:active {} */
/*} */

