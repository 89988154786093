footer {
    
    background-color: #023020;
    padding-top: 2rem;
    margin-top: 5rem;
}

 .footer-container {
    margin-top: -6rem;
    background: hsla(0, 0%, 100%, 1);
    
         background: linear-gradient(135deg, hsla(0, 0%, 100%, 1) 0%, hsla(120, 100%, 50%, 1) 100%);
     
        background: -moz-linear-gradient(135deg, hsla(0, 0%, 100%, 1) 0%, hsla(120, 100%, 50%, 1) 100%);
}

.footer-second-container {
    padding: 2rem;
    margin-top: 3rem;
}

.footer-row {
    margin: 0 auto;
    padding-top: 2rem;
    padding-bottom: 2rem;
}

.footer-btn {
    max-width: 100%;
    width: 12rem;
    color: #fff;
    background-color: green;
}

.footer-btn:hover {
    background-color: rgb(50, 205, 50)
}

.footer-social-links {
    margin-right: 1rem;
}

.footer-location-icon {
    color: #fff;
}

.footer-text-decoration {
    text-decoration: none;
    color: #fff;
    cursor: pointer;
}
.footer-heading {
    font-weight: 900;
    color: #E8DACC;
}

.footer-contact-text {
    color: #fff;

}

.footer-contact-display {
    display: inline;
    margin-left: 1rem;
}

.footer-border::after {
    content: "";
    height: 1px;
    width: 100%;
    background-color: #fff;
    display: block;
}

.subscribe {
 margin-right: 0.5rem;
 border-radius: 38px;
}
.footer-container-padding-last {
    padding: 0.5rem;
}

.footer-last-flex {
    display:flex;
    align-items: center;
    justify-content: space-between;
}

.footer-logo {
    width: 100px;
    height: 50px;
}

.footer-copyright {
    color: #fff;
}

@media (max-width: 450px) {
    .subscribe {
        display: none;
    }
    .footer-btn {
        width: 3rem;
    }
    .footer-logo {
        width: 100px;
    height: 50px;    }

    .footer-last-flex {
        display: flex;
        flex-direction: column;
    }

    /* .footer-copyright {
        width: 50%;
    } */
}

@media (max-width: 768px) {
    .heading {
        margin-bottom: 1rem;
    } 
}

@media(max-width: 992px) {
    .footer-row {
        display: flex;
        flex-direction: column;
    }
   form {
    margin-top: 1rem;
   }
}



















/* .footer-container-fluid {
    color: #fff;
    width: 100%;
    padding: 20px 0;
    text-align: center;
  
}

.footer-container-padding {
 padding: 20px;   
}

.footer-bg-img {
   
background-color: #49B649;
}

.footer-col-align {
    text-align: left;
}

.footer-text-header {
    font-family: poppins;
    cursor: pointer;
    font-size: 1rem;
}

.footer-paragraph {
    cursor: pointer;
    font-size: 0.8rem;
}

.footer-social-links {
    margin-right: 1.6rem;
} */