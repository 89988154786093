.video-player {
    max-width: 100%;;
}


@media (max-width: 992px) {
    .specialoffer {
        display: none;
    }
    /* .marginTop {
        margin-top: 4rem;
        padding-top: 4rem;
        margin-bottom: 4rem;
    } */
}

