.maintenance-heading {
        text-align: center;
            color: green;
            font-weight: 900;
}

.maintenance-txt {
    /* font-family: monteserrat; */
    font-size: larger;
    padding-top: 3rem;
}

.maintenance-col {
    background-color: #82CD47;
    color: #fff;
    padding: 1rem;
    flex: 1 1 auto;
}

.maintenance-col-headers {
    color: green;
    font-weight: bolder;
    text-align: center;
    border-bottom: 3px solid green;
    padding-bottom: 0.5rem;
}

.maintenance-second-head {
    width: 80%;
    margin: 1rem auto;
    /* border: 1px solid red; */
    color: green;
    font-weight: bolder;
    padding: 2rem;
    color: #fff;
}